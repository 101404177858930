import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import BlogPosts from '../components/BlogPosts'
import AboutSummary from '../components/AboutSummary'

export const IndexPageTemplate = ({
  blogPosts,
  allTags
}) => (
  <div>
    <div className="container max-w-postpage mx-auto">
      <div className="flex flex-wrap justify-around">
        <div className="w-full lg:w-content mx-4">
          <BlogPosts blogPosts={blogPosts}></BlogPosts>
        </div>
        <div className="w-full lg:w-sidebar h-about">
          <AboutSummary></AboutSummary>
          <div className="mx-4 lg:mx-0">
            <h2 className="text-xl mb-3 mt-12">Tags</h2>
            {allTags && allTags.map(tag => (
              <div key={tag.fieldValue}>
                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  <div className="w-48 pb-4 pt-2">{tag.fieldValue} ({tag.totalCount})</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  // const { frontmatter } = data.indexPage
  const blogPosts = data.blogPosts
  const group = data.allMarkdownRemark.group

  return (
    <Layout>
      <IndexPageTemplate
        // image={frontmatter.image}
        // title={frontmatter.title}
        // heading={frontmatter.heading}
        // subheading={frontmatter.subheading}
        // mainpitch={frontmatter.mainpitch}
        // description={frontmatter.description}
        // intro={frontmatter.intro}
        blogPosts={blogPosts}
        allTags={group}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    indexPage: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    blogPosts: PropTypes.shape({
      edges: PropTypes.array,
    }),
    recentPosts: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    indexPage: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        # image {
        #   childImageSharp {
        #     fluid(maxWidth: 2048, quality: 100) {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        # }
        # heading
        # subheading
        # mainpitch {
        #   title
        #   description
        # }
        # description
        # intro {
        #   blurbs {
        #     image {
        #       childImageSharp {
        #         fluid(maxWidth: 2048, quality: 100) {
        #           ...GatsbyImageSharpFluid
        #         }
        #       }
        #     }
        #     text
        #   }
        #   heading
        #   description
        # }
      }
    },
    blogPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "YYYY.MM.DD")
            description
            featuredpost
            # featuredimage {
            #   childImageSharp {
            #     fluid(maxWidth: 2048, quality: 100) {
            #       ...GatsbyImageSharpFluid
            #     }
            #   }
            # }
            tags
          }
        }
      }
    }
  }
`
